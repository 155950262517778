:root {
  /* FONTS */
  --font-light: 'Biennale-Light', sans-serif;
  --font: 'Biennale-Regular', sans-serif;
  --font-medium: 'Biennale-Medium', sans-serif;
  --font-bold: 'Biennale-Bold', sans-serif;
  /* VARIABLES */
  --spacing-unit: 16px;
  --outline: 0 0 0 4px var(--primary-focus);
  /* COLORS */
  --white: #fff;
  --black: #202020;
  --primary: #061e21;
  --secondary: #8f8f8f;
  --tertiary: #c1c7c8;
  --primary-focus: rgba(6, 30, 33, 0.25);
  --secondary-light: #f4f4f4;
  --secondary-primary: #eaeaea;
  --secondary-dark: #b3b3b3;
  --tertiary-light: #f0efea;
  --tertiary-primary: #e5e3d9;
  --tertiary-dark: #aeada5;
  --specialty-light: #99f0d4;
  --specialty-primary: #33e1aa;
  --specialty-dark: #29b488;
  --input-normal: #879191;
  --input-hover: #5d6565;
  --input-active: #0b3237;
  --sativa: #fe8b63;
  --sative-hybrid: #e8b143;
  --hybrid: #46a287;
  --indica-hybrid: #69abfa;
  --indica: #8470e3;
  --cbd: #0546af;
  --warning-light: #f9c37f;
  --warning-primary: #ef9829;
  --warning-dark: #bc6b03;
  --error-light: #fa927c;
  --error-primary: #f06041;
  --error-dark: #b72708;
  --success-light: #6dd995;
  --success-primary: #03c04a;
  --success-dark: #006f2a;
  --info-light: #97bef3;
  --info-primary: #2f80ed;
  --info-dark: #0045a1;
  --member: #cae1d1;
  --pro: #a6cdb2;
  --champion: #6bac7e;
  --disabled: #cacaca; // primary color at 0.5 opacity
}
