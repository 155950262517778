@import 'variables';

*,
*::before,
*::after {
  box-sizing: border-box;
  font-weight: unset;
}

*::-webkit-media-controls-panel {
  display: none !important;
  -webkit-appearance: none;
}

/* Old shadow dom for play button */
*::-webkit-media-controls-play-button {
  display: none !important;
  -webkit-appearance: none;
}

/* New shadow dom for play button */
*::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}

body {
  font-family: var(--font);
  color: var(--primary);
  margin: 0;
  position: relative;
}

body:has(.disable-scroll) {
  overflow: hidden;
}

h1,
.h1 {
  font-size: 60px;
  line-height: 78px;
  font-family: var(--font-bold);
  @include media-breakpoint-down(sm) {
    font-size: 40px;
    line-height: 46px;
  }
}
h2,
.h2 {
  font-family: var(--font-bold);
  font-size: 48px;
  line-height: 64px;
  @include media-breakpoint-down(sm) {
    font-size: 36px;
    line-height: 44px;
  }
}
h3,
.h3 {
  font-family: var(--font-bold);
  font-size: 34px;
  line-height: 48px;
}
h4,
.title-l {
  font-family: var(--font-bold);
  font-size: 24px;
  line-height: 34px;
}
h5,
.title-m {
  font-family: var(--font-bold);
  font-size: 20px;
  line-height: 30px;
}
h6,
.body-xl {
  font-family: var(--font-bold);
  font-size: 18px;
  line-height: 28px;
}

p,
a,
li,
button,
label,
.body-l {
  font-family: var(--font);
  font-size: 16px;
  line-height: 24px;
}

.body-m {
  font-family: var(--font);
  font-size: 14px;
  line-height: 20px;
}

small,
.body-sm {
  font-family: var(--font-medium);
  font-size: 12px;
  line-height: 18px;
}

.caption {
  font-family: var(--font);
  font-size: 10px;
  line-height: 14px;
}

b,
strong,
.font-bold {
  font-family: var(--font-bold);
}
.font-medium {
  font-family: var(--font-medium);
}
.font-regular {
  font-family: var(--font);
}
.font-light {
  font-family: var(--font-light);
}

a {
  // two color fields intentional. want anchor to inherit color otherwise fallback to primary.
  color: var(--primary);
  color: inherit;
  text-decoration: none;
  &:hover {
    color: var(--input-hover);
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
  margin-bottom: var(--spacing-unit);
}

.layout {
  margin: 0 auto;
  min-width: 375px;
  width: 100%;
}

span.pi {
  font-family: 'primeicons';
}

.preview-bar {
  min-width: 375px;
  width: 100%;
  display: inline-block;
  padding: 10px var(--spacing-unit);
  font-family: var(--font-bold);
  text-decoration: none;
  &:hover {
    opacity: 0.8;
  }
}

.pointer {
  cursor: pointer;
}
.uppercase {
  text-transform: uppercase;
}
.lowercase {
  text-transform: lowercase;
}
.capitalize {
  text-transform: capitalize;
}

.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}
.flex-column-tablet {
  @include media-breakpoint-down(tablet) {
    flex-direction: column;
  }
}
.flex-row {
  flex-direction: row;
}
.flex-wrap {
  flex-wrap: wrap;
}

.align-items-center {
  align-items: center;
}
.align-items-start {
  align-items: flex-start;
}
.align-items-end {
  align-items: flex-end;
}

.justify-content-center {
  justify-content: center;
}
.justify-content-between {
  justify-content: space-between;
}
.justify-content-evenly {
  justify-content: space-evenly;
}
.justify-content-end {
  justify-content: flex-end;
}

.sm-border-radius {
  border-radius: 8px;
}

.text-decoration-none {
  text-decoration: none;
}
.underline {
  text-decoration: underline;
}
.strikethrough {
  text-decoration: line-through;
}

.pointer {
  cursor: pointer;
}

.hover-underline {
  &:hover {
    text-decoration: underline;
  }
}

.button-group {
  display: flex;
  gap: 16px;
  width: 100%;
  > .cta-button {
    width: 50%;
  }
}

.container {
  padding: calc(var(--spacing-unit) * 2) var(--spacing-unit);
  @include media-breakpoint-up(md) {
    padding: calc(var(--spacing-unit) * 3) calc(var(--spacing-unit) * 2);
  }
}

.container-sm {
  > div {
    padding: calc(var(--spacing-unit) * 2);
    @include media-breakpoint-down(sm) {
      padding: var(--spacing-unit);
    }
  }
}

.container-lr {
  padding-inline: var(--spacing-unit);
  @include media-breakpoint-up(md) {
    padding-inline: calc(var(--spacing-unit) * 2);
  }
}

.container-tb {
  padding-top: calc(var(--spacing-unit) * 2);
  padding-bottom: calc(var(--spacing-unit) * 2);
  @include media-breakpoint-up(md) {
    padding-top: calc(var(--spacing-unit) * 3);
    padding-bottom: calc(var(--spacing-unit) * 3);
  }
}

.container-tb-sm {
  padding: var(--spacing-unit);
  padding: var(--spacing-unit);
  @include media-breakpoint-up(md) {
    padding-top: calc(var(--spacing-unit) * 2);
    padding-bottom: calc(var(--spacing-unit) * 2);
  }
}

.m-container {
  margin: calc(var(--spacing-unit) * 2) 0;
  padding: 0 var(--spacing-unit);
  @include media-breakpoint-up(md) {
    margin: calc(var(--spacing-unit) * 3) 0;
    padding: 0 calc(var(--spacing-unit) * 2);
  }
}

.m-container-tb {
  margin: calc(var(--spacing-unit) * 2) 0;
  @include media-breakpoint-up(md) {
    margin: calc(var(--spacing-unit) * 3) 0;
  }
}

.m-container-desktop {
  @include media-breakpoint-up(md) {
    margin: calc(var(--spacing-unit) * 3) 0;
    padding: 0 calc(var(--spacing-unit) * 2);
  }
}

.m-container-sm {
  margin: var(--spacing-unit) 0;
  > div {
    padding: 0 var(--spacing-unit);
  }
  @include media-breakpoint-up(md) {
    margin: calc(var(--spacing-unit) * 2) 0;
    > div {
      padding: 0 calc(var(--spacing-unit) * 2);
    }
  }
}

.m-container-tb {
  margin: calc(var(--spacing-unit) * 2) 0;
  @include media-breakpoint-up(md) {
    margin: calc(var(--spacing-unit) * 3) 0;
  }
}

.font-bold {
  font-family: var(--font-bold);
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}
.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.relative {
  position: relative;
}

@include media-breakpoint-up(md) {
  .justify-content-md-center {
    justify-content: center;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
}
@include media-breakpoint-up(lg) {
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
}

.bg-blur {
  backdrop-filter: blur(5px);
}

.close-icon {
  background: transparent;
  border: none;
  cursor: pointer;
  padding-inline: 0;
  &:hover {
    svg > path {
      fill: var(--input-normal);
    }
  }
}

a,
button {
  &:hover:not(:disabled) {
    opacity: 0.5;
  }
}

// Moodiday Ratings
.moodi-day-rating-overlay {
  .__md__rating__overlay__ > .__md__rating__overlay__text__ {
    color: var(--secondary-dark);
    .number {
      font-size: unset;
      line-height: unset;
    }
  }
}

$colors: (
  black: var(--black),
  primary: var(--primary),
  secondary: var(--secondary),
  secondary-light: var(--secondary-light),
  secondary-primary: var(--secondary-primary),
  secondary-dark: var(--secondary-dark),
  specialty-primary: var(--specialty-primary),
  specialty: var(--specialty-primary),
  specialty-light: var(--specialty-light),
  success-light: var(--success-light),
  success-primary: var(--success-primary),
  warning-light: var(--warning-light),
  error-light: var(--error-light),
  error-dark: var(--error-dark),
  white: var(--white),
  tertiary-light: var(--tertiary-light),
  tertiary-primary: var(--tertiary-primary),
  tertiary-dark: var(--tertiary-dark),
  sativa: var(--sativa),
  sative-hybrid: var(--sative-hybrid),
  hybrid: var(--hybrid),
  indica-hybrid: var(--indica-hybrid),
  indica: var(--indica),
  cbd: var(--cbd),
  member: var(--member),
  pro: var(--pro),
  champion: var(--champion),
  input-hover: var(--input-hover)
);

@each $color, $value in $colors {
  .bg-#{'' + $color} {
    background-color: $value;
    > div > p:last-child {
      margin-bottom: 0;
    }
  }
  .bb-#{'' + $color} {
    border-bottom: 1px solid $value;
  }
  .text-#{'' + $color} {
    color: $value;
  }
  .text-color-#{'' + $color} {
    // TO BE DEPRECATED
    color: $value;
  }
  .hover-color-#{'' + $color} {
    &:hover {
      color: $value;
    }
  }
}

$direction: ('right', 'left', 'top', 'bottom');
$spacing: (0, 3, 4, 5, 8, 10, 12, 15, 16, 20, 24, 25, 28, 30, 32, 40, 45, 50);

@each $len in $spacing {
  .padding-#{$len} {
    padding: #{$len}px;
  }
  .padding-inline-#{$len} {
    padding-inline: #{$len}px;
  }
  .column-gap-#{$len} {
    column-gap: #{$len}px;
  }
  .gap-#{$len} {
    gap: #{$len}px;
  }
  @media (min-width: 768px) {
    .d-gap-#{$len} {
      gap: #{$len}px;
    }
  }
  .margin-#{$len} {
    margin: #{$len}px;
  }
  .margin-inline-#{$len} {
    margin-inline: #{$len}px;
  }
  .margin-inline-#{$len}-mobile {
    @include media-breakpoint-down(sm) {
      margin-inline: #{$len}px;
    }
  }
  @each $dir in $direction {
    .padding-#{$dir}-#{$len} {
      padding-#{$dir}: #{$len}px;
    }
    .margin-#{$dir}-#{$len} {
      margin-#{$dir}: #{$len}px;
    }
  }
}

// for main site shop layout: min height includes marketing optin banner
.min-height-web-ecom {
  min-height: calc(100vh - 1111px);
  @media (min-width: 413px) {
    min-height: calc(100vh - 1093px);
  }
  @media (min-width: 421px) {
    min-height: calc(100vh - 1059px);
  }
  @media (min-width: 524px) {
    min-height: calc(100vh - 1041px);
  }
  @media (min-width: 525px) {
    min-height: calc(100vh - 1023px);
  }
  @media (min-width: 576px) {
    min-height: calc(100vh - 1007px);
  }
  @media (min-width: 691px) {
    min-height: calc(100vh - 989px);
  }
  @media (min-width: 758px) {
    min-height: calc(100vh - 955px);
  }
  @media (min-width: 768px) {
    min-height: calc(100vh - 1016px);
  }
  @media (min-width: 1048px) {
    min-height: calc(100vh - 968px);
  }
}

.min-height-kiosk-ecom {
  // to keep mailing banner of the bottom on the page
  min-height: calc(100vh - 597px); // 767
  @media (min-width: 787px) {
    min-height: calc(100vh - 579px);
  }
  @media (min-width: 1048px) {
    min-height: calc(100vh - 531px);
  }
}

// for ct site shop layout
.min-height-web-ecom-ct {
  min-height: calc(100vh - 634px);
  @media (min-width: 382px) {
    min-height: calc(100vh - 592px);
  }
  @media (min-width: 384px) {
    min-height: calc(100vh - 599px);
  }
  @media (min-width: 499px) {
    min-height: calc(100vh - 557px);
  }
  @media (min-width: 542px) {
    min-height: calc(100vh - 535px);
  }
  @media (min-width: 647px) {
    min-height: calc(100vh - 513px);
  }
  @media (min-width: 768px) {
    min-height: calc(100vh - 520px);
  }
  @media (min-width: 919px) {
    min-height: calc(100vh - 478px);
  }
}

// for ct site shop layout
.min-height-web-ecom-nd {
  min-height: calc(100vh - 807px);
  @media (min-width: 382px) {
    min-height: calc(100vh - 743px);
  }
  @media (min-width: 484px) {
    min-height: calc(100vh - 750px);
  }
  @media (min-width: 413px) {
    min-height: calc(100vh - 728px);
  }
  @media (min-width: 464px) {
    min-height: calc(100vh - 706px);
  }
  @media (min-width: 499px) {
    min-height: calc(100vh - 664px);
  }
  @media (min-width: 542px) {
    min-height: calc(100vh - 642px);
  }
  @media (min-width: 599px) {
    min-height: calc(100vh - 620px);
  }
  @media (min-width: 768px) {
    min-height: calc(100vh - 625px);
  }
  @media (min-width: 826px) {
    min-height: calc(100vh - 603px);
  }
  @media (min-width: 908px) {
    min-height: calc(100vh - 581px);
  }
  @media (min-width: 919px) {
    min-height: calc(100vh - 539px);
  }
}

// for main site main layout: min height excludes specials tray (bottom footer padding) & license bar (since not every dispensary has a license bar)
.min-height-web {
  min-height: calc(100vh - 786px);
  @media (min-width: 413px) {
    min-height: calc(100vh - 768px);
  }
  @media (min-width: 524px) {
    min-height: calc(100vh - 785px);
  }
  @media (min-width: 576px) {
    min-height: calc(100vh - 769px);
  }
  @media (min-width: 691px) {
    min-height: calc(100vh - 751px);
  }
  @media (min-width: 775px) {
    min-height: calc(100vh - 750px);
  }
}

// for state site main layout: min height excludes specials tray (bottom footer padding)
.min-height-web-ct {
  min-height: calc(100vh - 317px);
  @media (min-width: 647px) {
    min-height: calc(100vh - 295px);
  }
  @media (min-width: 768px) {
    min-height: calc(100vh - 302px);
  }
}

// for nd site main layout: min height excludes specials tray (bottom footer 80px padding)
.min-height-web-nd {
  min-height: calc(100vh - 490px);
  @media (min-width: 382px) {
    min-height: calc(100vh - 468px);
  }
  @media (min-width: 413px) {
    min-height: calc(100vh - 446px);
  }
  @media (min-width: 464px) {
    min-height: calc(100vh - 424px);
  }
  @media (min-width: 599px) {
    min-height: calc(100vh - 402px);
  }
  @media (min-width: 768px) {
    min-height: calc(100vh - 407px);
  }
  @media (min-width: 826px) {
    min-height: calc(100vh - 385px);
  }
  @media (min-width: 908px) {
    min-height: calc(100vh - 363px);
  }
}

// for kiosk main layout
.min-height-kiosk {
  min-height: calc(100vh - 419px);
  @media (min-width: 377px) {
    min-height: calc(100vh - 401px);
  }
  @media (min-width: 425px) {
    min-height: calc(100vh - 383px);
  }
  @media (min-width: 536px) {
    min-height: calc(100vh - 365px);
  }
  @media (min-width: 576px) {
    min-height: calc(100vh - 349px);
  }
  @media (min-width: 703px) {
    min-height: calc(100vh - 331px);
  }
  @media (min-width: 787px) {
    min-height: calc(100vh - 313px);
  }
}

// primereact overrides
.p-sidebar {
  color: var(--primary);
}
.p-component {
  font-family: var(--font);
  @extend .body-l;
}

// Chatbot
.genesys-mxg-frame {
  bottom: 80px !important;
  right: -12px !important;
  transform: scale(0.85);
  @media (max-width: 768px) {
    bottom: 95px !important;
    right: -20px !important;
    transform: scale(0.58);
  }
}
