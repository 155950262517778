@font-face {
  font-family: 'Biennale-Heavy';
  src: url('https://curaleafwebsitefonts.blob.core.windows.net/fonts/Biennale-Heavy.otf')
    format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Biennale-Bold';
  src: url('https://curaleafwebsitefonts.blob.core.windows.net/fonts/Biennale-Bold.otf')
    format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Biennale-Medium';
  src: url('https://curaleafwebsitefonts.blob.core.windows.net/fonts/Biennale-Medium.otf')
    format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Biennale-MediumIt';
  src: url('https://curaleafwebsitefonts.blob.core.windows.net/fonts/Biennale-MediumIt.otf')
    format('opentype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Biennale-Regular';
  src: url('https://curaleafwebsitefonts.blob.core.windows.net/fonts/Biennale-Regular.otf')
    format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Biennale-Light';
  src: url('https://curaleafwebsitefonts.blob.core.windows.net/fonts/Biennale-Light.otf')
    format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNowTextBold';
  src: url('https://curaleafwebsitefonts.blob.core.windows.net/fonts/HelveticaNowTextBold.ttf')
    format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNowTextMedium';
  src: url('https://curaleafwebsitefonts.blob.core.windows.net/fonts/HelveticaNowTextMedium.ttf')
    format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNowText';
  src: url('https://curaleafwebsitefonts.blob.core.windows.net/fonts/HelveticaNowText.otf')
    format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNowTextLight';
  src: url('https://curaleafwebsitefonts.blob.core.windows.net/fonts/HelveticaNowTextLight.ttf')
    format('truetype');
  font-weight: 300;
  font-style: normal;
}
